import { createSlice, current } from "@reduxjs/toolkit";
import { storeCookie, getCookie } from "../../utils/cookies";

const initialState = {
	initialBillersLoaded: [],
	billers: [],
	selectedBillers: [],
	isLoadingSelectedBiller: false,
	isBillerSuccess: false,
	selectedBiller: "",
	selectedPaymentMethod: "",
	billerToAdd: {
		name: "",
		checkNotAllowed: false,
		multipleCheckNotAllowed: false,
		maxBillersReached: false,
	},
	billerTransactions: {},
	billerOpener: {},
	isDisabledBillerDialogOpen: false,
	isServiceFeeRetryDialogOpen: false,
};

const billerList = createSlice({
	name: "billerList",
	initialState,
	reducers: {
		prep: (state, payload) => {
			state.selectedBiller = payload.code;
			state.isLoadingSelectedBiller = true;
			state.isBillerSuccess = false;
		},
		load: (state, payload) => {
			let billerInfo = payload.result;
			billerInfo.logo = state.billers.find(
				(biller) => biller.code === billerInfo.code
			).logo;
			state.billerOpener[billerInfo.code] = true;

			const paymentMethodParam = billerInfo.parameters.verify.filter(
				(parameter) => Object.keys(parameter)[0] === "paymentMethod"
			)[0].paymentMethod;
			const modes = Object.keys(paymentMethodParam.rules)
				.filter((methods) => methods.includes("in:"))[0]
				.replace("in:", "")
				.split(",");

			if (state.selectedBillers.length === 0) {
				state.selectedPaymentMethod = modes[0];
				state.selectedBillers =
					state.selectedBillers.concat(billerInfo);
				state.billerTransactions[state.selectedBiller.code] = [
					createTransaction(
						state.selectedBiller.code + 0,
						state.selectedBillers.find(
							(biller) =>
								biller.code === state.selectedBiller.code
						).parameters.verify
					),
				];

				state.isBillerSuccess = true;
			} else if (
				Object.keys(state.billerTransactions).reduce(
					(accumulator, txnKey) =>
						accumulator + state.billerTransactions[txnKey].length,
					0
				) === 30
			) {
				state.billers = state.billers.map((biller) => {
					if (biller.code === payload.result.code) {
						biller.isSelected = false;
						state.billerToAdd.name = biller.name;
					}
					return biller;
				});
				state.billerToAdd.maxBillersReached = true;
				state.isBillerSuccess = false;
			} else if (
				state.selectedPaymentMethod === "CASH" &&
				!modes.includes("CASH")
			) {
				state.billers = state.billers.map((biller) => {
					if (biller.code === payload.result.code) {
						biller.isSelected = false;
						state.billerToAdd.name = biller.name;
					}
					return biller;
				});
				state.billerToAdd.checkNotAllowed = true;
				state.isBillerSuccess = false;
			} else if (state.selectedPaymentMethod === "CHECK") {
				state.billers = state.billers.map((biller) => {
					if (biller.code === payload.result.code) {
						biller.isSelected = false;
						state.billerToAdd.name = biller.name;
					}
					return biller;
				});

				state.billerToAdd.multipleCheckNotAllowed = true;
				state.isBillerSuccess = false;
			} else {
				state.selectedBillers =
					state.selectedBillers.concat(billerInfo);
				state.billerTransactions[state.selectedBiller.code] = [
					createTransaction(
						state.selectedBiller.code + 0,
						state.selectedBillers.find(
							(biller) =>
								biller.code === state.selectedBiller.code
						).parameters.verify
					),
				];
				state.isBillerSuccess = true;
			}
			state.isLoadingSelectedBiller = false;
			state.isBillerSuccess = true;
		},
		setIsDisabledBillerDialogOpen: (state, { payload }) => {
			state.isDisabledBillerDialogOpen = payload;
		},
		listBillers: (state, action) => {
			state.billers = action.payload.billers;

			if (state.initialBillersLoaded.length <= 0) {
				state.initialBillersLoaded = action.payload.billers;
			}
		},
		remove: (state, { payload }) => {
			state.selectedBillers = state.selectedBillers.filter(
				(biller) => biller.code !== payload.code
			);
			state.billers = state.billers.map((biller) => {
				if (biller.code === payload.code) {
					biller.isSelected = false;
				}
				return biller;
			});
		},
		error: (state) => {
			console.log("Error");
			state.isBillerSuccess = false;
			state.isLoadingSelectedBiller = false;
		},
		allowCheck: (state) => {
			state.billerToAdd = {
				name: "",
				checkNotAllowed: false,
				multipleCheckNotAllowed: false,
				maxBillersReached: false,
			};
		},
		resetBillerTxn: (state) => {
			state.billerTransactions = {};
			state.selectedBillers = [];

			state.billers = state.billers.map((biller) => {
				return { ...biller, isSelected: false };
			});
		},
		resetCustomBillerServiceFee: (state) => {
			let billerTransactions = state.billerTransactions;
			for (const key in billerTransactions) {
				let code = key;
				if (isRegular(key) == false) {
					billerTransactions[key].forEach((item, index) => {
						state.billerTransactions[code][index]["otherCharges"] =
							"0.00";
					});
				}
			}
		},
		updateBillerServiceFee: (state,{payload}) => {
			console.log("payload: ",payload)
			const {billerCode,otherCharges} = payload;
			let billerTransactions = state.billerTransactions;
			for (const key in billerTransactions) {
				let code = key;
				if (code == billerCode) {
					billerTransactions[code].forEach((item, index) => {
						state.billerTransactions[code][index]["otherCharges"] =
							otherCharges;
					});
				}
			}

			updateCachedServiceFee(billerCode,otherCharges)
		},
		removeBillerTxn: (state, { payload }) => {
			state.billerTransactions[payload.code].splice(
				payload.indexToRemove,
				1
			);
			if (state.billerTransactions[payload.code].length <= 0) {
				const index = state.selectedBillers.findIndex(
					(biller) => biller.code === payload.code
				);
				state.selectedBillers[index] = {};
				state.selectedBillers = [...state.selectedBillers];
				state.billers = state.billers.map((biller) => {
					if (biller.code === payload.code) {
						biller.isSelected = false;
					}
					return biller;
				});
			}
		},
		addBillerTxn: (state, { payload }) => {
			const biller = state.selectedBillers.find(
				(biller) => biller.code === payload.code
			);
			const verifyFields = biller.parameters.verify;
			const newIndex =
				parseInt(
					state.billerTransactions[payload.code]
						.slice(-1)[0]
						.transactionKey.split(biller.code)[1]
				) + 1;
			if (
				Object.keys(state.billerTransactions).reduce(
					(accumulator, txnKey) =>
						accumulator + state.billerTransactions[txnKey].length,
					0
				) < 30
			) {
				state.billerTransactions[payload.code].push(
					createTransaction(payload.code + newIndex, verifyFields)
				);
				// insert notify
			} else {
				state.billerToAdd.name = biller.name;
				state.billerToAdd.maxBillersReached = true;
			}
		},
		updateBillerTxn: (state, { payload }) => {
			const index = state.billerTransactions[payload.code].findIndex(
				(txn) => txn.transactionKey === payload.txnKey
			);
			state.billerTransactions[payload.code][index][payload.key] =
				payload.value;
		},
		selectPaymentMethod: (state, { payload }) => {
			state.selectedPaymentMethod = payload.mode;
		},
		clearBillerTxns: (state) => {
			Object.keys(state.billerTransactions).forEach((code) => {
				state.billerTransactions[code].forEach((txn, index) => {
					Object.keys(txn)
						.filter((field) => field !== "transactionKey")
						.forEach((field) => {
							state.billerTransactions[code][index][field] = "";
						});
				});
			});
		},
		setMultipleCheckNotAllowed: (state) => {
			state.billerToAdd.multipleCheckNotAllowed = true;
		},
		toggleBillerOpener: (state, { payload }) => {
			state.billerOpener[payload.code] =
				!state.billerOpener[payload.code];
		},
		toggleisLoadingSelectedBiller: (state) => {
			state.isLoadingSelectedBiller = !state.isLoadingSelectedBiller;
		},
		setProductStatus: (state, { payload }) => {
			state.productStatus = payload;
		},
		setProductPartnerStatus: (state, { payload }) => {
			state.productPartnerStatus = payload;
		},
		toggleBFAStatusOpener: (state) => {
			state.showBFAStatus = !state.showBFAStatus;
		},
		toggleServiceFeeRetryDialog: (state) =>{
			console.log("CLOSE CLOSE CLOSE")
			console.log("state:",state)
			state.isServiceFeeRetryDialogOpen = !state.isServiceFeeRetryDialogOpen;
		}
	},
});

const {
	prep,
	load,
	listBillers,
	remove,
	error,
	allowCheck,
	removeBillerTxn,
	resetBillerTxn,
	resetCustomBillerServiceFee,
	updateBillerServiceFee,
	addBillerTxn,
	updateBillerTxn,
	selectPaymentMethod,
	clearBillerTxns,
	setMultipleCheckNotAllowed,
	toggleBillerOpener,
	toggleServiceFeeRetryDialog,
	toggleisLoadingSelectedBiller,
	setIsDisabledBillerDialogOpen,
	setProductStatus,
	setProductPartnerStatus,
	toggleBFAStatusOpener,
} = billerList.actions;

const getBillerDetails = async (client, state) => {
	const promises = [];
	const billerCode = state.billerList.selectedBiller.code;
	// check if service fee has already exist
	const isServiceFeeExist = isServiceFeeExists(billerCode);
	const billerInfo = client.get(`/v2/billers/${billerCode}`);
	promises.push(billerInfo);
	if (isServiceFeeExist == false) {
		const billerServiceFee = client.get(`/v2/billers/${billerCode}/fees`);
		promises.push(billerServiceFee);
	}
	const response = await Promise.allSettled(promises);
	// save to cookie after api request
	if (isServiceFeeExist == false) {
		response[0].value.data.data["otherCharges"] =
			response[1].status === "fulfilled"
				? response[1].value.data.data.otherCharges
				: "0.00";
		let { code } = response[0].value.data.data;
		const feeResponseStatusCode = response[1].status;
		let isRegular = 0;
		let otherCharges = 0.0;
		let isRequestSuccessful;
		if (
			response[1].status !== "rejected" &&
			response[1].value.data.data &&
			response[1].value.data.data.length !== 0
		) {
			const data = response[1].value.data.data;
			if (
				data.otherCharges.trim() !== "" &&
				!isNaN(parseFloat(data.otherCharges)) &&
				isFinite(data.otherCharges)
			) {
				otherCharges = response[0].value.data.data.otherCharges;
				isRegular = 1;
				isRequestSuccessful = true;
			}
			// handler for custom billers returning otherCharges:NaN value
			else if (data.otherCharges == "NaN") {
				isRequestSuccessful = true
			}
		}
		
		if (feeResponseStatusCode == "rejected") {
			const errorCode = response[1].reason.response.status
			otherCharges = 0.0;
			isRegular = 0;
			isRequestSuccessful = errorCode == 422 ? true : false;
		}

		storeServiceFee(code, otherCharges, isRegular, isRequestSuccessful);
	}
	return response[0].value.data;
};

function isServiceFeeExists(billerCode) {
	if (getCookie("service_fee")) {
		const list = getCookie("service_fee");
		return list.some((item) => item.code === billerCode);
	} else {
		return false;
	}
}

function isRegular(billerCode) {
	const list = getCookie("service_fee");
	const foundItem = list.find((item) => item.code === billerCode);
	if (foundItem.isRegular == 1) {
		return true;
	} else {
		return false;
	}
}

function updateCachedServiceFee(billerCode, otherCharges) {
	const midnight = new Date();
	midnight.setHours(23, 59, 59, 0);
	var list;

	// if cached list is already exist modify the list
	if (getCookie("service_fee")) {
		list = getCookie("service_fee");

		list = list.map(item => {
		if (item.code === billerCode) {
			return {
			...item,
			otherCharges: otherCharges, // Example update value, change as needed
			isRegular: 1, // Assuming 1 represents true/yes
			isRequestSuccessful: true
			};
		}
		return item;
		});

	} else {
		// if cached list is not yet exist save directly
		list = [
			{
				code: billerCode,
				otherCharges: otherCharges,
				isRegular: isRegular,
				isRequestSuccessful: true,
			},
		];
	}
	
	storeCookie("service_fee", list, midnight);
}

function storeServiceFee(billerCode, otherCharges, isRegular, isRequestSuccessful) {
	const midnight = new Date();
	midnight.setHours(23, 59, 59, 0);
	var list;

	// if cached list is already exist modify the list
	if (getCookie("service_fee")) {
		list = getCookie("service_fee");
		list.push({
			code: billerCode,
			otherCharges: otherCharges,
			isRegular: isRegular,
			isRequestSuccessful: isRequestSuccessful,
		});
	} else {
		// if cached list is not yet exist save directly
		list = [
			{
				code: billerCode,
				otherCharges: otherCharges,
				isRegular: isRegular,
				isRequestSuccessful: isRequestSuccessful,
			},
		];
	}

	storeCookie("service_fee", list, midnight);
}

function getServiceFee(billerCode) {
	billerCode = billerCode.slice(0, -1);

	const list = getCookie("service_fee");
	const foundItem = list.find((item) => item.code === billerCode);
	if (foundItem.isRegular == 1) {
		return foundItem.otherCharges;
	} else {
		return null;
	}
}
function billerActionCreator(code) {
	return {
		types: [prep.type, load.type, error.type],
		promise: getBillerDetails,
		code,
	};
}

function createTransaction(transactionCode, transactionFields) {
	return transactionFields
		.filter(
			(field) =>
				Object.keys(field)[0] !== "paymentMethod" &&
				Object.keys(field)[0] !== "otherCharges" &&
				!Object.keys(field)[0].startsWith("otherInfo.CheckDetails")
		)
		.reduce(
			(accumulator, fieldItem) => {
				return { ...accumulator, [Object.keys(fieldItem)[0]]: "" };
			},
			{
				transactionKey: transactionCode,
				otherCharges: getServiceFee(transactionCode),
			}
		);
}

export default billerList.reducer;

export {
	billerActionCreator,
	listBillers,
	remove,
	allowCheck,
	removeBillerTxn,
	resetBillerTxn,
	resetCustomBillerServiceFee,
	updateBillerServiceFee,
	addBillerTxn,
	updateBillerTxn,
	selectPaymentMethod,
	clearBillerTxns,
	setMultipleCheckNotAllowed,
	toggleBillerOpener,
	toggleServiceFeeRetryDialog,
	toggleisLoadingSelectedBiller,
	setIsDisabledBillerDialogOpen,
	setProductStatus,
	setProductPartnerStatus,
	toggleBFAStatusOpener,
}
