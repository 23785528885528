import { connect } from "react-redux";
import styles from "./BillSummary.module.css";
import { Dialog, Divider, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import {
	createPaymentActionCreator,
	showOrHideTransactionSummary,
	setDuplicateTransactionList,
} from "../../redux/modules/transaction";
import {
	resetValidated,
	addCash,
	setAdditionalCash,
	clearValidation,
	setPaymentDetails,
	resetBillSummaryFields,
	setConvenienceFee,
	updateBillerSummary,
	addConvenienceFee,
} from "../../redux/modules/validation";
import {
	clearBillerTxns,
	resetBillerTxn,
	updateBillerTxn,
} from "../../redux/modules/billerList";
import WalletBalanceCard from "../WalletBalanceCard/WalletBalanceCard";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";

import { setShowAlert } from "../../redux/modules/walletBalance";
import FullPageLoaderPercentage from "../TransactionList/Components/FullPageLoaderPercentage";
import FullPageLoader from "../TransactionList/Components/FullPageLoader";
import { SuccessModal } from "../Modal/SuccessModal";
import AmountField from "../TransactionList/Components/AmountField";
import NumberField from "../TransactionList/Components/NumberField";
import { getCookie } from "../../utils/cookies";

const useStyles = makeStyles(() => ({
	divider: {
		marginRight: "10px",
	},
	summaryLabel: {
		font: "normal normal normal 12px/37px Poppins-Regular",
		marginTop: "5px",
	},
	summaryValue: {
		font: "normal normal normal 14px/37px Poppins-Regular",
		fontWeight: 600,
		color: "#3B3B3B",
		marginRight: "10px",
	},
	serviceFeeLabel: {
		font: "normal normal normal 12px/29px Poppins-Regular",
		marginLeft: "10px",
		lineHeight: "1.2",
	},
	serviceFeeValue: {
		font: "normal normal normal 14px/29px Poppins-Regular",
		fontWeight: 300,
		height: "22px",
		opacity: "80%",
		color: "#3B3B3B",
		marginRight: "20px",
		lineHeight: "1.2",
	},
	redValue: {
		color: "#D93025",
	},
	inputTextField: {
		backgroundColor: "white",
		marginRight: "10px",
	},
	commitBtn: {
		width: "100%",
		marginTop: "40px",
		marginRight: "10px",
	},
	paymentAlert: {
		marginTop: "20px",
		marginBottom: "28px",
		font: "normal normal 600 18px/21px Wavehaus-SemiBold",
	},
	paymentDialog: {
		width: "357px",
		height: "270px",
		textAlign: "center",
	},
}));

const CssTextField = withStyles({
	root: {
		"& label.Mui-focused": {
			color: "#0176C0",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#0176C0",
		},
		"& .MuiOutlinedInput-root": {
			"&.Mui-focused fieldset": {
				borderColor: "#0176C0",
			},
		},
		"& .MuiInputBase-root.Mui-disabled": {
			backgroundColor: "rgb(243 243 243) !important",
		},
	},
})(NumberField);

export const OrangeColorButton = withStyles((theme) => ({
	root: {
		color: "#FFFFFF",
		backgroundColor: "#F26122",
		"&:hover": {
			backgroundColor: "#E1571C",
		},
	},
}))(Button);

const GreenColorButton = withStyles((theme) => ({
	root: {
		width: "126px",
		height: "42px",
		color: "#FFFFFF",
		backgroundColor: "#32AA4D",
		"&:hover": {
			backgroundColor: "#138906",
		},
	},
}))(Button);

const WhiteColorButton = withStyles((theme) => ({
	root: {
		width: "126px",
		height: "42px",
		color: "#4B5051",
		backgroundColor: "#FFFFFF",
		border: "1px solid #CED4DA",
		"&:hover": {
			backgroundColor: "#FFFFFF",
		},
	},
}))(Button);

const dialogStyles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: "22px",
		color: theme.palette.grey[500],
	},
	dialogTitle: {
		font: "normal normal 600 18px/21px Wavehaus-SemiBold;",
	},
});

export const DialogTitle = withStyles(dialogStyles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6" className={classes.dialogTitle}>
				{children}
			</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});
const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

function Tally({
	data,
	billSummaryFields = {},
	addCash,
	setAdditionalCash,
	setConvenienceFee,
	addConvenienceFee,
	selectedPaymentMethod,
	billerSFList,
	sfDisplayStatus,
}) {
	const classes = useStyles();
	const tcf = parseInt(billSummaryFields.totalConvenienceFee || 0);

	const handleAddCash = (event) => {
		setAdditionalCash(event.target.value);
		addCash();
	};

	const handleConvenienceFee = (event) => {
		setConvenienceFee(event.target.value);
		addConvenienceFee();
	};

	const getValueToDisplay = (obj) => {
		if (obj.code == "totalAmountDue" || obj.code == "totalConvenienceFee") {
			if (sfDisplayStatus) {
				return obj.value;
			} else {
				return <>TBD</>;
			}
		} else {
			return obj.value;
		}
	};

	return data
		.filter((obj) => {
			if (selectedPaymentMethod === "CHECK" && tcf > 0) {
				return obj;
			}

			return obj.code !== "convenienceFee";
		})
		.map((obj, index) => {
			return (
				<div key={index}>
					<Box display="flex" key={obj.code}>
						<Box flexGrow={1}>
							<Typography className={classes.summaryLabel}>
								{obj.label}
							</Typography>
						</Box>

						<Box>
							{obj.valueType === "inputText" ? (
								obj.code === "convenienceFee" && tcf > 0 ? (
									<>
										<CssTextField
											style={{
												border: "solid 2px green",
											}}
											id={obj.code}
											key={obj.code}
											className={classes.inputTextField}
											size="small"
											variant="outlined"
											value={
												billSummaryFields.convenienceFee
											}
											disabled={
												obj.code !== "convenienceFee"
											}
											fieldOnChange={(event) => {
												handleConvenienceFee(event);
											}}
										/>
									</>
								) : (
									<CssTextField
										id={obj.code}
										key={obj.code}
										className={classes.inputTextField}
										size="small"
										variant="outlined"
										value={billSummaryFields.additionalCash}
										disabled={
											selectedPaymentMethod == "CHECK"
										}
										fieldOnChange={(event) => {
											handleAddCash(event);
										}}
									/>
								)
							) : (
								<Typography
									key={obj.code}
									className={classes.summaryValue}
									style={{ color: obj.color }}
								>
									{getValueToDisplay(obj)}
								</Typography>
							)}
						</Box>
					</Box>
					{obj.code === "totalConvenienceFee" && (
						<>
							{billerSFList.map((item) => {
								return (
									<>
										<Box display={"flex"} key={item.name}>
											<Box flexGrow={1}>
												<Typography
													className={
														classes.serviceFeeLabel
													}
													style={{
														paddingRight: "10px",
													}}
												>
													{item.name}
												</Typography>
											</Box>
											<Box>
												<Typography
													key={obj.code}
													className={
														classes.serviceFeeValue
													}
													style={{ color: obj.color }}
												>
													{item.value}
												</Typography>
											</Box>
										</Box>
									</>
								);
							})}
						</>
					)}
					<Divider className={classes.divider} />
				</div>
			);
		});
}

function BillSummary({
	isPrefunded,
	isWalletSufficient,
	setShowAlert,
	isLoading,
	successPayment,
	createPaymentActionCreator,
	successValidation,
	isSuccess,
	billSummaryFields,
	paymentDetails,
	resetValidated,
	addCash,
	showTransactionList,
	transactionList,
	showOrHideTransactionSummary,
	setDuplicateTransactionList,
	validTransactions,
	failedTransactions,
	setAdditionalCash,
	setConvenienceFee,
	addConvenienceFee,
	clearValidation,
	setPaymentDetails,
	clearBillerTxns,
	resetBillerTxn,
	isDisableCommit,
	selectedPaymentMethod,
	resetBillSummaryFields,
	updateBillerSummary,
	overrideGenericError,
	processingPaymentMessage,
	successMessage,
	billerTransactions,
	updateBillerTxn,
	selectedBillers,
	isValidated,
	checkDuplicate
}) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [confirm, setConfirm] = React.useState(false);
	const [subtotalBillDue, setSubtotalBillDue] = React.useState(0);
	const [billerCount, setBillerCount] = React.useState(0);
	const [sfDisplayStatus, setSFDisplayStatus] = React.useState(true);
	const history = useHistory();
	const [billerSFList, setBillerSFList] = React.useState([]);
	const DIGIT_COMMA_REGEX = /\B(?=(\d{3})+(?!\d))/g;

	useEffect(()=>{
		if(checkDuplicate === false){
			setDuplicateTransactionList([]);
			handleConfirm();
		} 
	},[checkDuplicate])

	useEffect(() => {
		resetValidated();
		setAdditionalCash("");
		setConvenienceFee("");
		addCash();
		resetBillSummaryFields();
	}, [selectedPaymentMethod]);

	useEffect(() => {
		if (billerTransactions) {
			getBillerCountAndTotalBillDue(billerTransactions);
			setBillerSFList(getServiceFeeList());
			billerSummaryHandler();
			checkSFDisplayStatus();
		}
	}, [billerTransactions, isValidated]);

	// update biller summary when cash received changed
	useEffect(() => {
		updateBillerSummary(billerTransactions);
	}, [paymentDetails]);

	const billerSummaryHandler = () => {
		// reset payment details
		resetCustomBillerSF();

		updateBillerSummary(billerTransactions);
	};

	const resetCustomBillerSF = () => {
		if (Object.keys(billerTransactions).length == 0) {
			setPaymentDetails({});
		}
	};

	const getBillerCountAndTotalBillDue = (billerTransactions) => {
		let totalBillDue = 0;
		let totalBillerCount = 0;
		Object.values(billerTransactions).forEach((transactions) => {
			if (transactions.length > 0)
				totalBillerCount = totalBillerCount + transactions.length;
			transactions.forEach((data) => {
				if (data.amount != "") {
					totalBillDue = totalBillDue + parseFloat(data.amount);
				}
			});
		});
		setSubtotalBillDue(totalBillDue);
		setBillerCount(totalBillerCount);
	};

	const checkSFDisplayStatus = () => {
		const selectedBillerList = selectedBillers
			.map((item) => {
				if (item.code == undefined) {
					return;
				} else {
					return item.code;
				}
			})
			.filter((code) => code !== undefined);

		if (selectedBillers.length == 0) {
			setSFDisplayStatus(true);
		} else {
			let flag = true;
			for (let billerCode of selectedBillerList) {
				if (isRegular(billerCode) == false) {
					if (isValidated == false) {
						flag = false;
					}
				}
			}
			setSFDisplayStatus(flag);
		}
	};

	const isRegular = (billerCode) => {
		const list = getCookie("service_fee");
		const foundItem = list.find((item) => item.code === billerCode);
		if (foundItem.isRegular == 1) {
			return true;
		} else {
			return false;
		}
	};

	const getServiceFeeDisplayValue = (billerCode) => {
		if (isRegular(billerCode)) {
			return parseFloat(
				getOtherChargesByCode(billerCode) *
					billerTransactions[billerCode].length
			).toFixed(2);
		} else {
			if (isValidated) {
				let total_sf = 0;
				billerTransactions[billerCode].forEach((transaction) => {
					if (
						transaction.otherCharges !== null &&
						!isNaN(transaction.otherCharges)
					) {
						total_sf += parseInt(transaction.otherCharges);
					}
				});
				return total_sf.toFixed(2);
			}
			return "TBD";
		}
	};

	const getServiceFeeList = () => {
		const transCodeAndCountList = selectedBillers
			.filter((item) => billerTransactions[item.code])
			.map((item) => ({
				code: item.code,
				name: item.name,
				value: getServiceFeeDisplayValue(item.code),
			}));
		return transCodeAndCountList;
	};

	// get servicefee from cookie
	function getOtherChargesByCode(code) {
		const list = getCookie("service_fee");
		const foundItem = list.find((item) => item.code === code);

		if (!isNaN(foundItem.otherCharges)) {
			return foundItem.otherCharges;
		} else {
			return 0;
		}
	}

	const cleanup = () => {
		clearValidation();
		clearBillerTxns();
		resetBillerTxn();
		setPaymentDetails({});
		setSubtotalBillDue(0);
		setBillerCount(0);
	};

	React.useEffect(() => {
		cleanup();
	}, []);

	const handleClickOpen = () => {
		window.resetValidateExpiryTimeOut();
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setConfirm(false);
	};
	const closeAlert = (isProceed) => {
		setConfirm(false);
		resetValidated({ exclude: "timeoutError" });
		showOrHideTransactionSummary(isProceed);
		if (isProceed) {
			history.push("/");
			history.push("/pay-bills");
		}
	};
	const handleConfirm = () => {
		setShowAlert(false);
		setOpen(false);
		setConfirm(true);
		createPaymentActionCreator(validTransactions);
	};

	const billSummary = () => {
		return [
			{
				code: billSummaryFields.billCount,
				label: "Bill Count",
				value: billerCount,
				color: "#3B3B3B",
				valueType: "default",
			},
			{
				code: "totalBillDue",
				label: "Total Bill Due",
				value: subtotalBillDue
					.toFixed(2)
					.toString()
					.replace(DIGIT_COMMA_REGEX, ","),
				color: "#3B3B3B",
				valueType: "default",
			},
			{
				code: "totalConvenienceFee",
				label: "Total Convenience Fee",
				value: parseInt(billSummaryFields.totalConvenienceFee)
					.toFixed(2)
					.toString()
					.replace(DIGIT_COMMA_REGEX, ","),
				color: "#3B3B3B",
				valueType: "default",
			},
			{
				code: "totalBankCharge",
				label: "Total Bank Charge",
				value: billSummaryFields.totalBankCharge.replace(
					DIGIT_COMMA_REGEX,
					","
				),
				color: "#3B3B3B",
				valueType: "default",
			},
			{
				code: "totalChannelFee",
				label: "Total Channel Fee",
				value: billSummaryFields.totalChannelFee.replace(
					DIGIT_COMMA_REGEX,
					","
				),
				color: "#3B3B3B",
				valueType: "default",
			},
		];
	};
	const paymentSummary = () => {
		return [
			{
				code: "totalAmountDue",
				label: "Total Amount Due",
				value: billSummaryFields.totalAmountDue.replace(
					DIGIT_COMMA_REGEX,
					","
				),
				color: "#3B3B3B",
				valueType: "default",
			},
			{
				code: "cashReceived",
				label: "Cash Received",
				value: billSummaryFields.cashReceived.replace(
					DIGIT_COMMA_REGEX,
					","
				),
				color: "#3B3B3B",
				valueType: "default",
			},
			{
				code: "checkReceived",
				label: "Check Received",
				value: billSummaryFields.checkReceived.replace(
					DIGIT_COMMA_REGEX,
					","
				),
				color: "#3B3B3B",
				valueType: "default",
			},
			{
				code: "cashDue",
				label: "Cash Due",
				value: billSummaryFields.cashDue.replace(
					DIGIT_COMMA_REGEX,
					","
				),
				color:
					billSummaryFields.cashDue < 0 ||
					billSummaryFields.cashReceived <
						billSummaryFields.totalAmountDue
						? "#D93025"
						: billSummaryFields.cashDue > 0
						? "#28A745"
						: "#3B3B3B",
				valueType: "default",
			},
			{
				code: "additionalCash",
				label: "Additional Cash",
				value: "6500.00",
				color: "#3B3B3B",
				valueType: "inputText",
			},
			{
				code: "convenienceFee",
				label: "Convenience Fee",
				value: "6500.00",
				color: "#3B3B3B",
				valueType: "inputText",
			},
			{
				code: "totalReceived",
				label: "Total Received",
				value: billSummaryFields.totalReceived.replace(
					DIGIT_COMMA_REGEX,
					","
				),
				color: "#3B3B3B",
				valueType: "default",
			},
			{
				code: "change",
				label: "Change",
				value: billSummaryFields.change.replace(DIGIT_COMMA_REGEX, ","),
				color:
					billSummaryFields.change < 0
						? "#D93025"
						: billSummaryFields.change > 0
						? "#28A745"
						: "#3B3B3B",
				valueType: "default",
			},
		];
	};

	const failedTrans = Object.entries(failedTransactions).filter(
		(err) =>
			!Object.values(err[1][0].errors).every((errs) => errs.length === 0)
	);

	useEffect(() => {
		if (isSuccess && !isLoading) {
			cleanup();
		}
	}, [isLoading]);
	return (
		<div
			style={{
				"max-width": "400px",
				"min-width": "320px",
			}}
		>
			<WalletBalanceCard />
			<div>
				<p className={styles.summaryHeader}>Bill Summary</p>
				<Divider className={classes.divider} />
				<Tally
					data={billSummary()}
					billerSFList={billerSFList}
					sfDisplayStatus={sfDisplayStatus}
					style={{ border: "2px solid black" }}
				/>
				<p className={styles.summaryHeader}>Payment Summary</p>
				<Divider className={classes.divider} />
				<Tally
					data={paymentSummary()}
					addCash={addCash}
					addConvenienceFee={addConvenienceFee}
					sfDisplayStatus={sfDisplayStatus}
					setAdditionalCash={setAdditionalCash}
					setConvenienceFee={setConvenienceFee}
					billSummaryFields={billSummaryFields}
					selectedPaymentMethod={selectedPaymentMethod}
				/>
			</div>
			<div>
				<Box display="flex" justifyContent="center">
					{!showTransactionList && (
						<OrangeColorButton
							className={classes.commitBtn}
							variant="contained"
							onClick={handleClickOpen}
							disabled={
								failedTrans.length +
									Object.keys(validTransactions).length ===
									0 ||
								(failedTrans.length +
									Object.keys(validTransactions).length >
									0 &&
									failedTrans.length > 0) ||
								parseFloat(billSummaryFields.change) < 0 ||
								isDisableCommit ||
								(!isWalletSufficient && isPrefunded)
							}
						>
							Commit Payment
						</OrangeColorButton>
					)}
					<Dialog
						onClose={handleClose}
						aria-labelledby="customized-dialog-title"
						open={open}
						disableBackdropClick
					>
						<DialogTitle
							id="customized-dialog-title"
							onClose={handleClose}
						>
							Commit Payment
						</DialogTitle>
						<DialogContent dividers>
							<Typography
								gutterBottom
								className={styles.dialogContent}
							>
								Are you sure you want to proceed?
							</Typography>
						</DialogContent>
						<DialogActions>
							<WhiteColorButton
								autoFocus
								onClick={handleClose}
								color="primary"
							>
								Cancel
							</WhiteColorButton>
							<GreenColorButton
								autoFocus
								onClick={handleConfirm}
								color="primary"
							>
								Confirm
							</GreenColorButton>
						</DialogActions>
					</Dialog>
					<Dialog
						open={isLoading}
						PaperProps={{
							style: {
								backgroundColor: "transparent",
								boxShadow: "none",
							},
						}}
					>
						<FullPageLoader
							open={isLoading}
							message={
								processingPaymentMessage ||
								"Please wait while we validate your payment request..."
							}
						/>
					</Dialog>
					<Dialog
						open={
							((!isSuccess && confirm && !isLoading) ||
								(confirm &&
									!isLoading &&
									transactionList.filter(
										(txn) => txn.paymentStatus === "FAILED"
									).length === transactionList.length)) &&
							overrideGenericError
						}
					>
						<Grid
							container
							direction="column"
							justify="center"
							alignItems="center"
							className={classes.paymentDialog}
						>
							<Grid item>
								<HighlightOffIcon
									style={{ fontSize: 85, color: "#D93025" }}
								/>
							</Grid>
							<Grid item>
								<Typography className={classes.paymentAlert}>
									Transaction Failed!
								</Typography>
								<p className={classes.errorDetails}>
									{transactionList.length > 0
										? transactionList[0].note
										: ""}
								</p>
							</Grid>
							<Grid item>
								<WhiteColorButton
									autoFocus
									onClick={() => closeAlert(false)}
									color="primary"
								>
									Back
								</WhiteColorButton>
							</Grid>
						</Grid>
					</Dialog>
					{/* Success Modal for Payments */}
					<SuccessModal
						toggleDialog={() => closeAlert(true)}
						aria-labelledby="customized-dialog-title"
						open={
							isSuccess &&
							confirm &&
							!isLoading &&
							transactionList.filter(
								(txn) => txn.paymentStatus === "FAILED"
							).length !== transactionList.length
						}
						title="Transaction Successful!"
						message={successMessage}
						buttonTitle="Print Receipt"
						disableBackdropClick
					/>
				</Box>
			</div>
		</div>
	);
}

export default connect(
	(state) => ({
		isPrefunded: state.walletBalance.type === "prefunded",
		isWalletSufficient: state.walletBalance.isWalletSufficient,
		isLoading: state.transaction.isLoading,
		successPayment: state.transaction.successPayment,
		isSuccess: state.transaction.isSuccess,
		successValidation: state.validation.successValidation,
		billSummaryFields: state.validation.billSummaryFields,
		paymentDetails: state.validation.paymentDetails,
		showTransactionList: state.transaction.showTransactionList,
		transactionList: state.transaction.transactionList,
		validTransactions: state.validation.validTransactions,
		failedTransactions: state.validation.failedTransactions,
		isDisableCommit: state.timeout.isDisableCommit,
		selectedPaymentMethod: state.billerList.selectedPaymentMethod,
		selectedBillers: state.billerList.selectedBillers,
		overrideGenericError: !state.transaction.paymentErrorCode,
		processingPaymentMessage: state.transaction.processingPaymentMessage,
		successMessage: state.transaction.successMessage,
		billerTransactions: state.billerList.billerTransactions,
		isValidated: state.validation.isValidated,
		checkDuplicate : state.transaction.checkDuplicate
	}),
	{
		setShowAlert,
		createPaymentActionCreator,
		resetValidated,
		addCash,
		updateBillerTxn,
		showOrHideTransactionSummary,
		setDuplicateTransactionList,
		setAdditionalCash,
		setConvenienceFee,
		addConvenienceFee,
		clearValidation,
		clearBillerTxns,
		resetBillerTxn,
		setPaymentDetails,
		resetBillSummaryFields,
		updateBillerSummary,
	}
)(BillSummary);
